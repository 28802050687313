import { BaseQueryFn, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logout } from 'actions/user';
import { getAuth } from 'firebase/auth';
import { setInternalServerErrorDialogVisible } from 'reducers/internalServerErrorDialogSlice';
import { INTERMEDIARY_API_PATH } from 'utils/constants';
import firebaseClient from 'utils/firebase';
import { captureException } from 'utils/sentry';

const GET_CONVENIENCE_FEE = '/payments/convenience-fee';

const baseQueryWithAuth: BaseQueryFn = async (args, api, extraOptions) => {
  const baseQuery = fetchBaseQuery({ baseUrl: INTERMEDIARY_API_PATH });

  try {
    const { currentUser } = await getAuth(firebaseClient);

    const headers = currentUser ? { Authorization: `Bearer ${await currentUser.getIdToken()}` } : {};

    const result = await baseQuery(
      {
        ...args,
        headers: {
          ...headers,
          ...(args.headers || {}),
        },
      },
      api,
      extraOptions,
    );

    if (result.error) {
      const { data, status } = result.error;

      if (status === 401 || status === 403) {
        api.dispatch(logout());
      } else if (status === 500) {
        captureException(new Error(`HTTP Status: ${status} / Data: ${data}`));
        api.dispatch(setInternalServerErrorDialogVisible(true));
      }
    }

    return result;
  } catch (error) {
    return { error: { status: 500, data: `Unexpected Error: ${error}` } };
  }
};

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getConvenienceFee: builder.query<any, IConvenienceFeeFactors | undefined>({
      query: (loanInfo: IConvenienceFeeFactors) => ({
        url: GET_CONVENIENCE_FEE,
        method: 'POST',
        body: loanInfo,
      }),
    }),
  }),
});

export const { useGetConvenienceFeeQuery } = baseApi;
