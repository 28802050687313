import { combineReducers } from '@reduxjs/toolkit';

import app from './appSlice';
import user from './user';
import loan from './loan';
import paymentMethod from './paymentMethod';
import payment from './payment';
import internalServerErrorDialog from './internalServerErrorDialogSlice';
import primaryPaymentMethodInfo from './primaryPaymentMethodInfoSlice';
import documents from './documents';
import { baseApi } from 'apis/reduxApis';

export const combinedReducers = combineReducers({
  app,
  user,
  loan,
  paymentMethod,
  payment,
  internalServerErrorDialog,
  primaryPaymentMethodInfo,
  documents,
  [baseApi.reducerPath]: baseApi.reducer,
});
