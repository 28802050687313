import { all } from 'redux-saga/effects';

import user from './user';
import loan from './loan';
import paymentMethod from './paymentMethod';
import payment from './payment';
import documents from './documents';

export default function* sagas() {
  yield all([user(), loan(), paymentMethod(), payment(), documents()]);
}
